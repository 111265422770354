import React, { useState } from 'react';

import { Button, FetchButton, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import { ListIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';

import { useAddAppNews } from 'src/Hooks/HomeEdition/useAddAppNews';
import { useUpdateAppNews } from 'src/Hooks/HomeEdition/useUpdateAppNews';
import { RandstadAppEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { EditAppNewsState } from 'src/Services/Routing';

import { EditionAppNewsMode } from './EditAppNewsModal.types';
import EditAppNewsModalContent from './EditAppNewsModalContent/EditAppNewsModalContent.component';

const modalTitle = {
  [EditionAppNewsMode.CREATE]: 'ajouter un paragraphe sur les apps',
  [EditionAppNewsMode.MODIFY]: "modifier le paragraphe sur l'app",
};

const hook = {
  [EditionAppNewsMode.CREATE]: useAddAppNews,
  [EditionAppNewsMode.MODIFY]: useUpdateAppNews,
};

const EditAppNewsModal = () => {
  const history = useHistory();
  const location = useLocation<EditAppNewsState>();
  const modalParameters = location.state.modalParameters;
  const editionAppNewsMode = modalParameters?.editionAppNewsMode ?? EditionAppNewsMode.CREATE;
  const appNews = modalParameters?.appNews;

  const [app, setApp] = useState<keyof RandstadAppEnum | null>(
    (appNews?.app ?? null) as keyof RandstadAppEnum | null
  );
  const [version, setVersion] = useState<string>(appNews?.version ?? '');
  const [newsTitle, setNewsTitle] = useState<string>(appNews?.newsTitle ?? '');
  const [description, setDescription] = useState<string>(appNews?.description ?? '');
  const editAppNews = hook[editionAppNewsMode]();
  const onClose = () => {
    history.goBack();
  };

  return (
    <Modal
      open
      icon={<ListIcon />}
      title={modalTitle[editionAppNewsMode]}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setApp((appNews?.app ?? null) as keyof RandstadAppEnum | null);
            setVersion(appNews?.version ?? '');
            setNewsTitle(appNews?.newsTitle ?? '');
            setDescription(appNews?.description ?? '');
          }}
        >
          réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={
            app === null ||
            version.trim().length === 0 ||
            newsTitle.trim().length === 0 ||
            (app.toString() === appNews?.app?.toString() &&
              version === appNews.version &&
              newsTitle === appNews.newsTitle &&
              description === appNews.description)
          }
          onClick={() =>
            editAppNews.mutate({
              isArchived: modalParameters?.isArchived ?? false,
              appNews: {
                ...appNews,
                app: (app as RandstadAppEnum) ?? undefined,
                version,
                newsTitle,
                description,
              },
            })
          }
          fetchStatus={toFetchStatus(editAppNews)}
          onSuccess={onClose}
        />,
      ]}
      onClose={onClose}
    >
      <EditAppNewsModalContent
        app={app}
        version={version}
        newsTitle={newsTitle}
        description={description}
        setApp={setApp}
        setVersion={setVersion}
        setNewsTitle={setNewsTitle}
        setDescription={setDescription}
      />
    </Modal>
  );
};

export default EditAppNewsModal;
