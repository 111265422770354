import React, { useCallback } from 'react';

import { Button, DropDown, toast } from '@randstad-lean-mobile-factory/react-components-core';
import {
  CircleCheck,
  CircleError,
  DownArrow,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import ContentLoader from 'react-content-loader';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import BackButton from 'src/Components/BackButton';
import CopyTextInput from 'src/Components/CopyTextInput';
import ErrorMessage from 'src/Components/ErrorMessage';
import InputLabel from 'src/Components/InputLabel';
import { useGetAgencyCalendars } from 'src/Hooks/CustomRandy/useGetAgencyCalendars';
import { useGetAgencyConfig } from 'src/Hooks/CustomRandy/useGetAgencyConfig';
import { useGetCustomLink } from 'src/Hooks/CustomRandy/useGetCustomLink';
import { useUpdateCustomLink } from 'src/Hooks/CustomRandy/useUpdateCustomLink';
import { CalendarEntity, OfferStatus } from 'src/Services/API';
import history from 'src/Utils/history';

import { CustomRandySection, CustomRandySubSection, URL } from '../../CustomRandy.types';

import Styles from './InspectCustomLinkScreen.module.scss';

const InspectCustomLinkScreen = () => {
  const queryClient = useQueryClient();
  const { link } = useParams<{ link: string }>();
  const { data, isLoading, error } = useGetCustomLink(link);
  const { data: agencyConfig } = useGetAgencyConfig(data?.agencyId);
  const { data: calendars } = useGetAgencyCalendars(data?.agencyId);

  const { mutate: updateCustomLink, isLoading: isSaving } = useUpdateCustomLink({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['custom-links', link]);
      toast.success('Enregistrement réussi');
    },
    onError: err => {
      toast.error(`erreur lors de l'enregistrement: ${err.message}`);
    },
  });

  const url = `${process.env.REACT_APP_CHATBOT_LINK}/?randy_ref=offer:${link}&`;
  const qrCode = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${encodeURIComponent(
    url
  )}`;

  const downloadQrCode = useCallback(async () => {
    const response = await fetch(qrCode);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = `${data?.companyName} - ${data?.qualificationLabel}.png`;
    a.href = blobUrl;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }, [qrCode, data]);

  return (
    <div className={Styles.InspectCustomLinkScreen}>
      <BackButton
        text="retour"
        onClick={() =>
          history.push(`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}`)
        }
      />
      {isLoading && (
        <ContentLoader height="10rem" width="100%">
          <rect x="0" y="0" rx="4" ry="4" width="80%" height="40" />
          <rect x="0" y="80" rx="4" ry="4" width="20%" height="30" />
        </ContentLoader>
      )}
      {error && <ErrorMessage error={error} />}
      {data && (
        <>
          <div className={Styles.TitleContainer}>
            <div className={Styles.TextContainer}>
              <h2 className={Styles.Title}>
                {data.companyName} <span>(siret: {data.companySiret})</span>
              </h2>
              <div className={Styles.Subtitle}>{data.qualificationLabel}</div>
            </div>

            {agencyConfig && calendars && (
              <div className={Styles.OptionalSteps}>
                <div className={Styles.OptionalStep}>
                  coordonnées de l'agence
                  {agencyConfig.coords?.email && agencyConfig.coords?.phoneNumber ? (
                    <div className={Styles.Success}>
                      <CircleCheck />
                    </div>
                  ) : (
                    <div className={Styles.Danger}>
                      <CircleError />
                    </div>
                  )}
                </div>
                <div className={Styles.OptionalStep}>
                  proposition de session
                  {calendars.find(c => c._id === data.calendar) ? (
                    <div className={Styles.Success}>
                      <CircleCheck />
                    </div>
                  ) : (
                    <div className={Styles.Danger}>
                      <CircleError />
                    </div>
                  )}
                </div>
                <InputLabel label="choix du calendrier">
                  <DropDown
                    name="calendar"
                    placeholder="aucun calendrier associé"
                    disabled={!calendars.length || isSaving}
                    items={calendars}
                    selectedItem={calendars.find(c => c._id === data.calendar)}
                    keyValueExtractor={(calendar: CalendarEntity) => ({
                      key: calendar._id,
                      value: calendar.title,
                    })}
                    className={Styles.DropDown}
                    onSelectItem={(item?: CalendarEntity) => {
                      updateCustomLink({ link, body: { calendar: item?._id } });
                    }}
                  />
                </InputLabel>
              </div>
            )}
          </div>

          {data.offerStatus === OfferStatus.ACTIVE ? (
            <p className={Styles.Paragraph}>
              Ce lien est <span className={Styles.SuccessColor}>actif</span> car des critères de
              présélection Osmose existent
            </p>
          ) : (
            <p className={Styles.Paragraph}>
              Ce lien est <span className={Styles.WarnColor}>inactif</span> car les critères de
              présélection Osmose ont été supprimés
            </p>
          )}

          <InputLabel label="lien vers l'offre">
            <CopyTextInput value={url} copiedText="lien copié dans le presse papiers" />
          </InputLabel>

          <InputLabel label="code qr" />
          <div className={Styles.QRCodeContainer}>
            <img src={qrCode} alt="qr code" />
            <Button text="télécharger" rightIcon={<DownArrow />} onClick={downloadQrCode} />
          </div>
        </>
      )}
    </div>
  );
};

export default InspectCustomLinkScreen;
