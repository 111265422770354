import React from 'react';

import {
  DropDown,
  TextArea,
  ToggleSwitch,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';

import { useFetchAppDetails } from 'src/Hooks/HomeEdition/useFetchAppDetails';
import { RandstadAppEnum } from 'src/Services/API';
import { appsConstants } from 'src/Utils/AppsDetailsValues/AppsDetailsValues';

import { EditionAppDetailsMode } from '../EditAppDetailsModal.types';

import styles from './EditAppDetailsModalContent.module.scss';
import { Props } from './EditAppDetailsModalContent.types';

const EditAppDetailsModalContent = ({
  editionAppDetailsMode,
  app,
  category,
  description,
  isHighlighted,
  setApp,
  setDescription,
  setIsHighlighted,
}: Props) => {
  const { data: appDetails } = useFetchAppDetails();
  return (
    <div className={styles.container}>
      <WithLightTitle title="catégorie de l'application">{category}</WithLightTitle>
      <WithLightTitle title="app / service">
        {editionAppDetailsMode === EditionAppDetailsMode.CREATE ? (
          <DropDown
            items={Object.keys(RandstadAppEnum).filter(
              appName =>
                !appDetails?.map(appDetail => appDetail.app).includes(appName as RandstadAppEnum)
            )}
            selectedItem={app}
            placeholder="sélectionnez"
            keyValueExtractor={(searchResult: RandstadAppEnum) => ({
              key: searchResult,
              value: appsConstants[searchResult].fullName ?? 'application non renseignée',
            })}
            onSelectItem={(item: RandstadAppEnum) => setApp(item)}
          />
        ) : (
          (app && appsConstants[app].fullName) ?? 'application non renseignée'
        )}
      </WithLightTitle>
      <WithLightTitle title="texte descriptif" className={styles.descriptionContainer}>
        <TextArea
          className={styles.descriptionContainer}
          value={description}
          placeholder="décrivez la nouveauté ici"
          onChange={event => setDescription((event.target as HTMLInputElement).value)}
          maxLength={128}
        />
      </WithLightTitle>
      <div className={styles.toggleContainer}>
        <p>mettre cette application en avant</p>
        <ToggleSwitch
          checked={isHighlighted}
          onCheckStatusChange={value => setIsHighlighted(value)}
        />
      </div>
    </div>
  );
};

export default EditAppDetailsModalContent;
