import React from 'react';

import {
  DropDown,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';

import { rightProfileToString } from 'src/Containers/AdminPage/RightsSection/RightsSection.types';
import { useFetchAuthorizedModifiedProfiles } from 'src/Hooks/Rights/useFetchAuthorizedModifiedProfiles';
import { RightsProfile } from 'src/Services/API';

import { EditionRightMode } from '../EditRightModal.types';

import styles from './EditRightModalContent.module.scss';
import { Props, RenderConsultantPartParams } from './EditRightModalContent.types';

const renderConsultantPart = {
  [EditionRightMode.CREATE]: ({
    consultantEmail,
    setConsultantEmail,
  }: RenderConsultantPartParams) => (
    <WithLightTitle title="entrez l'adresse mail du collaborateur">
      <TextInput
        value={consultantEmail}
        placeholder="ex : jean.dupont@randstad.fr"
        onChange={event => setConsultantEmail((event.target as HTMLInputElement).value)}
      />
    </WithLightTitle>
  ),
  [EditionRightMode.MODIFY]: ({ right }: RenderConsultantPartParams) => (
    <WithLightTitle title="collaborateur">
      <div>
        {`${right?.consultantFirstName} ${right?.consultantName} (${right?.consultantEmail})`}
      </div>
    </WithLightTitle>
  ),
};

const EditRightModalContent = ({
  editionRightMode,
  right,
  consultantEmail,
  setConsultantEmail,
  selectedProfile,
  setSelectedProfile,
  setShowDetails,
}: Props) => {
  const authorizedModifiedProfiles = useFetchAuthorizedModifiedProfiles();
  return (
    <>
      {renderConsultantPart[editionRightMode]({ consultantEmail, setConsultantEmail, right })}
      <WithLightTitle
        className={styles.profileContainer}
        title="type de profil"
        rightTitleComponent={
          <div className={styles.seeDetails} onClick={() => setShowDetails(true)}>
            voir les droits associés aux profils
          </div>
        }
      >
        <DropDown
          key="profileDropDown"
          selectedItem={selectedProfile}
          placeholder="choisir un profil..."
          items={authorizedModifiedProfiles.data ?? []}
          keyValueExtractor={(profile: RightsProfile) => ({
            key: profile,
            value: rightProfileToString[profile],
          })}
          onSelectItem={(item: RightsProfile) => {
            setSelectedProfile(item);
          }}
          childrenClassName={styles.profileDropdownChildren}
        />
      </WithLightTitle>
    </>
  );
};

export default EditRightModalContent;
