import React, { useEffect, useState } from 'react';

import {
  Button,
  DropDown,
  FetchButton,
  Modal,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { FlowIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';

import { useAddUnitsToCGC } from 'src/Hooks/CGC/useAddCGCToUnits';
import { useFetchCGCs } from 'src/Hooks/CGC/useFetchCGCs';
import { useReassignUnitToCGC } from 'src/Hooks/CGC/useReassignUnitToCGC';
import { CGCWithUnits } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { AddCGCToUnitsState } from 'src/Services/Routing';

import styles from './AddCGCToUnitsModal.module.scss';
import { EditionCGCMode } from './AddCGCToUnitsModal.types';

const AddCGCToUnitsModal = () => {
  const history = useHistory();
  const location = useLocation<AddCGCToUnitsState>();
  const modalParameters = location.state.modalParameters;
  const editionCGCMode = modalParameters?.editionCGCMode ?? EditionCGCMode.ADD_UNIT;
  const units = modalParameters?.units ?? [];
  const previousCGC = modalParameters?.previousCGC;
  const fetchCGCs = useFetchCGCs();

  const [selectedCGC, setSelectedCGC] = useState<CGCWithUnits | undefined>(undefined);
  const useAddUnitsToCGCMutation = useAddUnitsToCGC();
  const useReassignUnitToCGCMutation = useReassignUnitToCGC();

  const onClose = () => {
    history.push('/admin/business/cgc');
  };

  useEffect(() => {
    if (
      editionCGCMode === EditionCGCMode.REASSIGN_UNIT &&
      previousCGC &&
      selectedCGC === undefined &&
      fetchCGCs.data
    ) {
      const previousCGCValue = fetchCGCs.data.find(cgc => cgc.id === previousCGC);
      setSelectedCGC(previousCGCValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCGCs.data]);

  return (
    <Modal
      open
      icon={<FlowIcon />}
      title="attribution"
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedCGC(undefined);
          }}
        >
          réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={
            selectedCGC === undefined ||
            units === undefined ||
            units.length === 0 ||
            (editionCGCMode === EditionCGCMode.REASSIGN_UNIT && selectedCGC.id === previousCGC)
          }
          onClick={() =>
            editionCGCMode === EditionCGCMode.ADD_UNIT
              ? useAddUnitsToCGCMutation.mutate({
                  cgcId: selectedCGC?.id ?? '',
                  unitIds: units,
                })
              : useReassignUnitToCGCMutation.mutate({
                  unitId: units[0],
                  previousCgcId: previousCGC ?? '',
                  newCgcId: selectedCGC?.id ?? '',
                })
          }
          fetchStatus={toFetchStatus(
            editionCGCMode === EditionCGCMode.ADD_UNIT
              ? useAddUnitsToCGCMutation
              : useReassignUnitToCGCMutation
          )}
          onSuccess={onClose}
        />,
      ]}
      onClose={onClose}
    >
      <WithLightTitle
        title={
          editionCGCMode === EditionCGCMode.ADD_UNIT
            ? 'trouvez le CGC à qui attribuer les unités'
            : `trouvez le CGC à qui réattribuer l'unité ${units.length > 0 ? units[0] : ''}`
        }
      >
        <DropDown
          key="cgcDropDown"
          selectedItem={selectedCGC}
          placeholder="choisir un CGC..."
          items={fetchCGCs.data ?? []}
          keyValueExtractor={(cgc: CGCWithUnits) => ({
            key: cgc.id,
            value: cgc.label,
          })}
          onSelectItem={(cgc: CGCWithUnits) => {
            setSelectedCGC(cgc);
          }}
          childrenClassName={styles.cgcDropdownChildren}
        />
      </WithLightTitle>
    </Modal>
  );
};

export default AddCGCToUnitsModal;
