import React, { useState } from 'react';

import { Button, FetchButton, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import { PeopleIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';

import { useOverrideCGCUnits } from 'src/Hooks/CGC/useOverrideCGCUnits';
import { Unit } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { EditCGCState } from 'src/Services/Routing';

import EditCGCModalContent from './EditCGCModalContent';

const EditCGCModal = () => {
  const history = useHistory();
  const location = useLocation<EditCGCState>();
  const modalParameters = location.state.modalParameters;
  const cgcWithUnits = modalParameters?.cgcWithUnits;
  const [cgcNewUnits, setCGCNewUnits] = useState<Unit[]>(cgcWithUnits?.units ?? []);

  const useOverrideCGCUnitsMutation = useOverrideCGCUnits();

  const onClose = () => {
    history.goBack();
  };

  return (
    <Modal
      open
      icon={<PeopleIcon />}
      title="détail sur le CGC"
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={cgcNewUnits === cgcWithUnits?.units}
          onClick={() =>
            useOverrideCGCUnitsMutation.mutate({
              cgcId: cgcWithUnits?.id ?? '',
              unitIds: cgcNewUnits.map(unit => unit.id),
            })
          }
          fetchStatus={toFetchStatus(useOverrideCGCUnitsMutation)}
          onSuccess={onClose}
        />,
      ]}
      onClose={onClose}
    >
      <EditCGCModalContent
        cgcLabel={cgcWithUnits?.label ?? ''}
        cgcNewUnits={cgcNewUnits}
        setCGCNewUnits={setCGCNewUnits}
      />
    </Modal>
  );
};

export default EditCGCModal;
