import React, { useState } from 'react';

import {
  Button,
  HorizontalCardWithTitle,
  Loader,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import BackButton from 'src/Components/BackButton';
import CustomDropdownComponent from 'src/Components/CustomDropdown/CustomDropdownComponent';
import InputLabel from 'src/Components/InputLabel';
import { useCreateCustomLink } from 'src/Hooks/CustomRandy/useCreateCustomLink';
import { useGetAgencyCalendars } from 'src/Hooks/CustomRandy/useGetAgencyCalendars';
import { useGetAgencyList } from 'src/Hooks/CustomRandy/useGetAgencyList';
import { useGetCompanies } from 'src/Hooks/CustomRandy/useGetCompanies';
import { useGetExistingLink } from 'src/Hooks/CustomRandy/useGetExistingLink';
import { useGetQualifications } from 'src/Hooks/CustomRandy/useGetQualifications';
import { Company, Qualification } from 'src/Services/API';
import history from 'src/Utils/history';

import { CustomRandySection, CustomRandySubSection, URL } from '../../CustomRandy.types';

import Styles from './CreateCustomLinkScreen.module.scss';

const CreateCustomLinkScreen = () => {
  const [agencyId, setAgencyId] = useState<string>();
  const [company, setCompany] = useState<Company>();
  const [qualification, setQualification] = useState<Qualification>();
  const [calendar, setCalendar] = useState<string>();

  const { data: agencies, isLoading: agenciesLoading } = useGetAgencyList();
  const { data: companies, isLoading: companiesLoading } = useGetCompanies(agencyId);
  const { data: qualifications, isLoading: qualificationsLoading } = useGetQualifications(
    agencyId,
    company?.companyId
  );
  const { data: calendars } = useGetAgencyCalendars(agencyId, {
    onSuccess: data => {
      setCalendar(data?.at(0)?._id);
    },
  });
  const { data: existingLink, isLoading: existingLinkLoading } = useGetExistingLink(
    agencyId,
    company?.companyId,
    qualification?.qualificationId
  );

  const { mutate: createLink } = useCreateCustomLink();

  return (
    <div className={Styles.CreateCustomLinkScreen}>
      <BackButton
        text="retour"
        onClick={() =>
          history.push(`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}`)
        }
      />
      <h2 className={Styles.Title}>créer un lien personnalisé</h2>

      <InputLabel label="agence">
        <CustomDropdownComponent
          items={agencies?.agencies ?? []}
          selectedItem={agencyId}
          onSelectItem={agencyId => {
            setAgencyId(agencyId);
            setCompany(undefined);
          }}
          placeholder={'sélectionnez une agence'}
          keyValueExtractor={agency => ({
            item: agency,
            value: agency,
            label: agency,
          })}
          triggerIcon={agenciesLoading && <Loader heightInRem={1.5} darkMode />}
        />
      </InputLabel>

      {agencyId && (
        <>
          <InputLabel label="client de la présélection Osmose">
            <CustomDropdownComponent
              items={companies ?? []}
              selectedItem={company}
              onSelectItem={company => {
                setCompany(company);
                setQualification(undefined);
              }}
              placeholder={'sélectionnez un client'}
              keyValueExtractor={company => ({
                item: company,
                value: company.companyId,
                label: `${company.companyName} (siret: ${company.companySiret})`,
              })}
              triggerIcon={companiesLoading && <Loader heightInRem={1.5} darkMode />}
            />
          </InputLabel>
          {company && (
            <>
              <InputLabel label="qualification">
                <CustomDropdownComponent
                  items={qualifications ?? []}
                  selectedItem={qualification}
                  onSelectItem={qualification => setQualification(qualification)}
                  placeholder={'sélectionnez une qualification'}
                  keyValueExtractor={qualification => ({
                    item: qualification,
                    value: qualification.qualificationId,
                    label: qualification.qualificationLabel,
                  })}
                  triggerIcon={qualificationsLoading && <Loader heightInRem={1.5} darkMode />}
                />
              </InputLabel>
              <p className={Styles.Paragraph}>
                Vous ne trouvez pas la qualification que vous cherchez ? Vérifiez que les critères
                de présélection ont bien été définis dans Osmose.
              </p>
              {qualification && (
                <>
                  {existingLinkLoading ? (
                    <Loader heightInRem={2} darkMode />
                  ) : (
                    <>
                      {existingLink ? (
                        <>
                          1 lien existant trouvé :
                          <HorizontalCardWithTitle
                            title={`agence ${agencyId} - ${company.companyName} (siret: ${company.companySiret})`}
                            subtitles={[qualification.qualificationLabel]}
                            onClick={() => {
                              history.push(
                                `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}/${existingLink}`
                              );
                            }}
                            className={Styles.Card}
                          />
                        </>
                      ) : !calendars ? (
                        <Loader heightInRem={2} darkMode />
                      ) : (
                        <>
                          {calendars.length > 0 && (
                            <InputLabel label="calendrier associé">
                              <CustomDropdownComponent
                                items={calendars}
                                selectedItem={calendars.find(c => c._id === calendar)}
                                onSelectItem={c => setCalendar(c?._id)}
                                placeholder={'sélectionnez un calendrier'}
                                keyValueExtractor={qualification => ({
                                  item: qualification,
                                  value: qualification._id,
                                  label: qualification.title,
                                })}
                                disabled={calendars.length === 1}
                              />
                            </InputLabel>
                          )}
                          <Button.Medium
                            rightIcon={<PlusCircle />}
                            text="créer un lien"
                            onClick={() => {
                              createLink(
                                {
                                  agencyId,
                                  ...company,
                                  ...qualification,
                                  calendar,
                                },
                                {
                                  onSuccess: data => {
                                    history.push(
                                      `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}/${data}`
                                    );
                                  },
                                }
                              );
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CreateCustomLinkScreen;
