import React, { useCallback } from 'react';

import { Button, Loader, toast } from '@randstad-lean-mobile-factory/react-components-core';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import BackButton from 'src/Components/BackButton';
import InputLabel from 'src/Components/InputLabel';
import { useAddAgencyCalendar } from 'src/Hooks/CustomRandy/useAddAgencyCalendar';
import history from 'src/Utils/history';

import {
  AGENDA_HELP_LINK,
  CustomRandySection,
  CustomRandySubSection,
  URL,
} from '../../CustomRandy.types';

import Styles from './AgencyAddCalendarScreen.module.scss';

const AgencyAddCalendarScreen = () => {
  const queryClient = useQueryClient();
  const { agencyId } = useParams<{ agencyId: string }>();

  const { mutate: addAgencyCalendar, isLoading } = useAddAgencyCalendar({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['agency-calendars', agencyId]);
      history.push(
        `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/${agencyId}`
      );
    },
    onError: err => {
      switch (err.cause) {
        case 'PRIVILEGES':
          toast.error("le compte de service n'a pas de droits en écriture sur ce calendrier");
          break;
        case 'BAD_EMAIL':
          toast.error('compte de service inconnu');
          break;
        case 'BAD_CALENDAR':
          toast.error('identifiant de calendrier inconnu');
          break;
        case 'UNKNOWN':
        default:
          toast.error(`erreur lors de l'enregistrement: ${err.message}`);
      }
    },
  });

  const schema = z.object({
    title: z.string().min(1),
    calendarId: z
      .string()
      .regex(/^c_[0-9a-f]{64}@group\.calendar\.google\.com$/, 'identifiant invalide'),
    delegatedEmail: z
      .string()
      .email('adresse email invalide')
      .regex(/@randstad\.fr$/, 'le compte de service doit avoir une adresse @randstad.fr'),
  });

  const { control, handleSubmit, formState } = useFormWithZodResolver({
    schema,
  });

  const submit = useCallback(
    (values: z.infer<typeof schema>) => {
      addAgencyCalendar({ agencyId, body: values });
    },
    [addAgencyCalendar, agencyId]
  );

  return (
    <form className={Styles.AgencyAddCalendarScreen} onSubmit={handleSubmit(submit)}>
      <BackButton
        text="retour"
        onClick={() =>
          history.push(
            `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/${agencyId}`
          )
        }
      />
      <h2 className={Styles.Title}>agence {agencyId}</h2>
      <p className={Styles.Paragraph}>ajouter un calendrier</p>
      <a href={AGENDA_HELP_LINK} target="_blank" className={Styles.Link} rel="noreferrer">
        guide de paramétrage de l'agenda
      </a>

      <InputLabel label="nom du calendrier">
        <TextInput
          placeholder="ex: recrutement collectif techniciens"
          name="title"
          control={control}
        />
      </InputLabel>

      <InputLabel
        label="identifiant de calendrier"
        explanation="se référer à la partie 4 du guide de paramétrage"
      >
        <TextInput
          placeholder="ex: c_48a271e49bdf650572549f4e263c009cabc0aac7567@group.calendar.google.com"
          name="calendarId"
          control={control}
        />
      </InputLabel>

      <InputLabel
        label="compte de service"
        explanation="se référer à la partie 3 du guide de paramétrage"
      >
        <TextInput
          placeholder="ex: adresse.email@randstad.fr"
          name="delegatedEmail"
          control={control}
        />
      </InputLabel>

      <Button.Primary
        text="ajouter un calendrier"
        disabled={isLoading || !formState.isDirty || !formState.isValid}
        rightIcon={isLoading ? <Loader heightInRem={2} /> : undefined}
      />
    </form>
  );
};

export default AgencyAddCalendarScreen;
