import React from 'react';

import { css } from '@emotion/css';
import { PopupActions } from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';

import { appsConstants } from 'src/Utils/AppsDetailsValues/AppsDetailsValues';

import AppButton from './AppButton';
import HighlightedAppButton from './HighlightedAppButton';
import styles from './RandstadAppsDetails.module.scss';
import { Props } from './RandstadAppsDetails.types';

const RandstadAppsDetails = React.forwardRef<PopupActions, Props>(
  ({ isProd, width, appDetails }) => {
    const highlightedApps = appDetails.filter(app => app.isHighlighted);
    const notHighlightedApps = appDetails.filter(app => !app.isHighlighted);
    return (
      <div
        className={classNames(
          styles.container,
          css`
            width: ${width};
          `
        )}
      >
        {highlightedApps.map(highlightedApp => {
          const constants = appsConstants[highlightedApp.app];
          return (
            <HighlightedAppButton
              key={highlightedApp.app}
              name={constants?.fullName ?? highlightedApp.app}
              backgroundStyle={constants?.iconStyle ?? ''}
              category={constants?.category ?? ''}
              description={highlightedApp.description ?? ''}
              link={(isProd ? constants?.link.prod : constants?.link.uat) ?? ''}
            />
          );
        })}
        <div className={styles.appsContainer}>
          {notHighlightedApps.map(notHighlightedApp => {
            const constants = appsConstants[notHighlightedApp.app];
            return (
              <AppButton
                key={notHighlightedApp.app}
                icon={isProd ? constants?.icon.prod : constants?.icon.uat}
                iconStyle={constants?.iconStyle ?? ''}
                name={constants?.fullName ?? notHighlightedApp.app}
                category={constants?.category ?? ''}
                description={notHighlightedApp.description ?? ''}
                link={(isProd ? constants?.link.prod : constants?.link.uat) ?? ''}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

export default RandstadAppsDetails;
