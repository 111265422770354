import React, { useState } from 'react';

import { Button, ParametersPopup } from '@randstad-lean-mobile-factory/react-components-core';
import { AdminIcon, Gear } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { security } from 'src/Services/API/Interceptors';

import styles from './HomeParametersPopOverMenu.module.scss';
import { Props } from './HomeParametersPopOverMenu.types';

const HomeParametersPopOverMenu = (props: Props) => {
  const userUnitaryRights = security.getUserUnitaryRights();
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  return (
    <ParametersPopup
      trigger={
        <div>
          <Button.Tertiary
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Gear title="gear" />
          </Button.Tertiary>
        </div>
      }
      position="bottom left"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
      children={
        userUnitaryRights !== undefined && userUnitaryRights.length > 0 ? (
          <Button.Tertiary
            className={styles.button}
            onClick={() => {
              history.push('/admin');
            }}
          >
            <AdminIcon viewBox="15 15 20 20" className={styles.adminIcon} />
            accéder à l'administration
          </Button.Tertiary>
        ) : undefined
      }
      {...props}
    />
  );
};
export default HomeParametersPopOverMenu;
