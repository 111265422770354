import { UseQueryOptions, useQuery } from 'react-query';

import { CalendarEntity, CalendarService } from 'src/Services/API';

export const useGetAgencyCalendar = (
  agencyId: string,
  calendarObjectId: string,
  config?: UseQueryOptions<CalendarEntity, Error>
) => {
  return useQuery<CalendarEntity, Error>(
    ['agency-calendars', agencyId, calendarObjectId],
    async () => {
      const calendars = await CalendarService.calendarControllerGetCalendars({ agencyId });
      const calendar = calendars.find(calendar => calendar._id === calendarObjectId);
      if (!calendar) throw new Error(`Calendar ${calendarObjectId} not found`);
      return calendar;
    },
    {
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
