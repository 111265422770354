/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url,
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class RightsService {
  /**
   *
   */
  static rightsControllerGetProfilesRights(
    options: IRequestOptions = {}
  ): Promise<ProfilesRights[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rights/profilesRights';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rightsControllerGetUserUnitaryRights(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rights/userUnitaryRights';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rightsControllerGetAuthorizedModifiedProfiles(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rights/authorizedModifiedProfiles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rightsControllerGetRights(options: IRequestOptions = {}): Promise<Right[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rights';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rightsControllerAddRights(
    params: {
      /** requestBody */
      body?: AddRightsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Right> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rights';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rightsControllerUpdateRights(
    params: {
      /** requestBody */
      body?: UpdateRightsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rights';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rightsControllerDeleteRights(
    params: {
      /**  */
      consultantSiid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rights/{consultantSiid}';
      url = url.replace('{consultantSiid}', params['consultantSiid'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class HomeEditionService {
  /**
   *
   */
  static homeEditionControllerGetHomePageConfig(
    options: IRequestOptions = {}
  ): Promise<HomePageConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/home-page-format';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerUpdateHomePageConfig(
    params: {
      /** requestBody */
      body?: UpdateHomePageConfigParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/home-page-format';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerGetAllAppNews(options: IRequestOptions = {}): Promise<AllAppNews> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/all-app-news';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerGetAppNews(options: IRequestOptions = {}): Promise<AppNews[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-news';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerAddAppNews(
    params: {
      /** requestBody */
      body?: AddAppNewsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-news';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerUpdateAppNews(
    params: {
      /** requestBody */
      body?: UpdateAppNewsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-news';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerDeleteAppNews(
    params: {
      /** requestBody */
      body?: DeleteAppNewsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-news';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerUpdateAppNewsOrder(
    params: {
      /** requestBody */
      body?: UpdateAppNewsOrderParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<object[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-news-order';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerMoveAppNewsToArchivedOrNot(
    params: {
      /** requestBody */
      body?: MoveAppNewsToArchivedOrNotParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-news-archive-or-not';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerGetAppDetails(options: IRequestOptions = {}): Promise<AppDetail[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-details';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerAddAppDetails(
    params: {
      /** requestBody */
      body?: AddAppDetailsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DBAppDetails[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-details';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerUpdateAppDetails(
    params: {
      /** requestBody */
      body?: UpdateAppDetailsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<object[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-details';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static homeEditionControllerDeleteAppDetails(
    params: {
      /** requestBody */
      body?: DeleteAppDetailsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/home-edition/app-details';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SkillsManagementCentersService {
  /**
   *
   */
  static skillsManagementCentersControllerGetCgCs(
    options: IRequestOptions = {}
  ): Promise<CGCWithUnits[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skillsManagementCenters';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static skillsManagementCentersControllerGetUnitsWithoutCgCs(
    params: {
      /**  */
      limit: number;
      /**  */
      offset: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UnitsWithTotalCount> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skillsManagementCenters/unitsWithoutCGCs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'], offset: params['offset'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static skillsManagementCentersControllerAddUnitsToCgc(
    params: {
      /** requestBody */
      body?: PutUnitsToCGCParam;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skillsManagementCenters/unitsToCGC';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static skillsManagementCentersControllerOverrideUnitsToCgc(
    params: {
      /** requestBody */
      body?: PutUnitsToCGCParam;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skillsManagementCenters/overrideUnitsToCGC';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static skillsManagementCentersControllerReassignUnitToCgc(
    params: {
      /** requestBody */
      body?: ReassignUnitToCGCParam;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skillsManagementCenters/reassignUnitToCGC';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AgenciesService {
  /**
   *
   */
  static agenciesControllerGetConsultantAgencies(
    options: IRequestOptions = {}
  ): Promise<ConsultantAgencies> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static agenciesControllerGetCompanies(
    params: {
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Company[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/{agencyId}/companies';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static agenciesControllerGetQualifications(
    params: {
      /**  */
      agencyId: string;
      /**  */
      companyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/{agencyId}/companies/{companyId}/qualifications';
      url = url.replace('{agencyId}', params['agencyId'] + '');
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static agenciesControllerGetConfig(
    params: {
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgencyConfigDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static agenciesControllerUpdateConfig(
    params: {
      /**  */
      agencyId: string;
      /** requestBody */
      body?: AgencyConfigDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static agenciesControllerPrepopulateConfig(
    params: {
      /**  */
      agencyId: string;
      /** requestBody */
      body?: AgencyConfigDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CalendarService {
  /**
   *
   */
  static calendarControllerGetCalendars(
    params: {
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalendarEntity[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/calendar/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static calendarControllerAddCalendar(
    params: {
      /**  */
      agencyId: string;
      /** requestBody */
      body?: CalendarDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/calendar/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static calendarControllerEditCalendar(
    params: {
      /**  */
      agencyId: string;
      /**  */
      calendarObjectId: string;
      /** requestBody */
      body?: CalendarDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/calendar/{agencyId}/{calendarObjectId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');
      url = url.replace('{calendarObjectId}', params['calendarObjectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static calendarControllerRemoveCalendar(
    params: {
      /**  */
      agencyId: string;
      /**  */
      calendarObjectId: string;
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/calendar/{agencyId}/{calendarObjectId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');
      url = url.replace('{calendarObjectId}', params['calendarObjectId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CustomLinksService {
  /**
   *
   */
  static customLinksControllerGetAll(
    options: IRequestOptions = {}
  ): Promise<CustomLinksResultsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerCreateLink(
    params: {
      /** requestBody */
      body?: OfferInfoDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerDeleteAllLinks(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerGetOne(
    params: {
      /**  */
      link: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomLinksResultsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links/{link}';
      url = url.replace('{link}', params['link'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerUpdateLink(
    params: {
      /**  */
      link: string;
      /** requestBody */
      body?: UpdateLinkDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links/{link}';
      url = url.replace('{link}', params['link'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerGetQuestions(
    params: {
      /**  */
      link: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferWithQuestions> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links/{link}/questions';
      url = url.replace('{link}', params['link'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerGetExistingLink(
    params: {
      /** requestBody */
      body?: LinkExistsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links/existing';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerApplyToOffer(
    params: {
      /**  */
      link: string;
      /** requestBody */
      body?: ApplyToOfferParamsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links/{link}/apply';
      url = url.replace('{link}', params['link'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerSetLinks(
    params: {
      /** requestBody */
      body?: SetLinksParamsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links/links';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customLinksControllerSearchCandidatePreselections(
    params: {
      /**  */
      link: string;
      /** requestBody */
      body?: GetPreselectionsFilters;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetPreselectionsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/custom-links/{link}/preselections';
      url = url.replace('{link}', params['link'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface ProfilesRights {
  /**  */
  profile: RightsProfile;

  /**  */
  unitaryRights: UnitaryRight[];
}

export interface Right {
  /**  */
  profile: RightsProfile;

  /**  */
  consultantSiid: string;

  /**  */
  consultantName?: string;

  /**  */
  consultantFirstName?: string;

  /**  */
  consultantEmail?: string;
}

export interface AddRightsParams {
  /**  */
  profile: Profile;

  /**  */
  consultantEmail: string;
}

export interface UpdateRightsParams {
  /**  */
  profile: Profile;

  /**  */
  consultantSiid: string;
}

export interface HomePageConfig {
  /**  */
  homePageFormat: HomePageFormat;

  /**  */
  homePageTitle: string;
}

export interface UpdateHomePageConfigParams {
  /**  */
  homePageFormat: HomePageFormat;

  /**  */
  homePageTitle: string;
}

export interface OSMConsultantModelDtoFullConsultantDto {
  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;
}

export interface AppNews {
  /**  */
  app?: RandstadAppEnum;

  /**  */
  id?: string;

  /**  */
  createdAt?: Date;

  /**  */
  updatedAt?: Date;

  /**  */
  lastUpdateConsultant?: OSMConsultantModelDtoFullConsultantDto;

  /**  */
  version?: string;

  /**  */
  newsTitle?: string;

  /**  */
  description?: string;

  /**  */
  order?: number;
}

export interface AllAppNews {
  /**  */
  notArchived: AppNews[];

  /**  */
  archived: AppNews[];
}

export interface AddAppNewsParams {
  /**  */
  appNews: object;
}

export interface UpdateAppNewsParams {
  /**  */
  isArchived: boolean;

  /**  */
  appNews: AppNews;
}

export interface DeleteAppNewsParams {
  /**  */
  isArchived: boolean;

  /**  */
  appNewsId: string;
}

export interface UpdateAppNewsOrderParams {
  /**  */
  isArchived: boolean;

  /**  */
  appNewsToUpdate: AppNews[];
}

export interface MoveAppNewsToArchivedOrNotParams {
  /**  */
  toArchived: boolean;

  /**  */
  appNewsId: string;
}

export interface AppDetail {
  /**  */
  app: RandstadAppEnum;

  /**  */
  description?: string;

  /**  */
  order?: number;

  /**  */
  isHighlighted?: boolean;
}

export interface AddAppDetailsParams {
  /**  */
  appDetails: AppDetail[];
}

export interface DBAppDetails {}

export interface UpdateAppDetailsParams {
  /**  */
  appDetails: AppDetail[];
}

export interface DeleteAppDetailsParams {
  /**  */
  app: RandstadAppEnum;
}

export interface Unit {
  /**  */
  id: string;

  /**  */
  label: string;
}

export interface CGCWithUnits {
  /**  */
  id: string;

  /**  */
  label: string;

  /**  */
  units: Unit[];
}

export interface UnitsWithTotalCount {
  /**  */
  totalCount: number;

  /**  */
  units: Unit[];
}

export interface PutUnitsToCGCParam {
  /**  */
  cgcId: string;

  /**  */
  unitIds: string[];
}

export interface ReassignUnitToCGCParam {
  /**  */
  unitId: string;

  /**  */
  previousCgcId: string;

  /**  */
  newCgcId: string;
}

export interface ConsultantAgencies {
  /**  */
  defaultAgency?: string;

  /**  */
  agencies: string[];
}

export interface Company {
  /**  */
  companyId: string;

  /**  */
  companySiret: string;

  /**  */
  companyName: string;
}

export interface Qualification {
  /**  */
  qualificationId: string;

  /**  */
  qualificationLabel: string;
}

export interface AgencyCoordsDto {
  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;
}

export interface AgencyConfigDto {
  /**  */
  coords?: AgencyCoordsDto;
}

export interface CalendarEntity {
  /**  */
  _id: string;

  /**  */
  title: string;

  /**  */
  calendarId: string;

  /**  */
  delegatedEmail: string;
}

export interface CalendarDto {
  /**  */
  title: string;

  /**  */
  calendarId: string;

  /**  */
  delegatedEmail: string;
}

export interface CustomLinksResultsDto {
  /**  */
  offerStatus: OfferStatus;

  /**  */
  offerLink: string;

  /**  */
  agencyId: string;

  /**  */
  qualificationId: string;

  /**  */
  qualificationLabel: string;

  /**  */
  companyId: string;

  /**  */
  companySiret: string;

  /**  */
  companyName: string;

  /**  */
  calendar?: string;
}

export interface UpdateLinkDto {
  /**  */
  calendar?: string;
}

export interface OSMCompanyModelSelectionCriteriaDtoSelectionCriteriaQuestionDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OfferWithQuestions {
  /**  */
  offerLink: string;

  /**  */
  offerStatus: string;

  /**  */
  qualificationLabel: string;

  /**  */
  companyName: string;

  /**  */
  calendar?: CalendarDto;

  /**  */
  questions: OSMCompanyModelSelectionCriteriaDtoSelectionCriteriaQuestionDto[];

  /**  */
  agencyId: string;

  /**  */
  agencyEmail?: string;

  /**  */
  agencyPhoneNumber?: string;
}

export interface OfferInfoDto {
  /**  */
  agencyId: string;

  /**  */
  qualificationId: string;

  /**  */
  qualificationLabel: string;

  /**  */
  companyId: string;

  /**  */
  companySiret: string;

  /**  */
  companyName: string;

  /**  */
  calendar?: string;
}

export interface LinkExistsDto {
  /**  */
  agencyId: string;

  /**  */
  companyId: string;

  /**  */
  qualificationId: string;
}

export interface PreselectionResponse {
  /**  */
  questionId: string;

  /**  */
  value: number;
}

export interface SessionDate {
  /**  */
  year: number;

  /**  */
  month: number;

  /**  */
  day: number;
}

export interface ApplyToOfferParamsDto {
  /**  */
  lastName: string;

  /**  */
  firstName: string;

  /**  */
  phoneNumber: string;

  /**  */
  email: string;

  /**  */
  zipCode: string;

  /**  */
  city: string;

  /**  */
  origin?: string;

  /**  */
  responses: PreselectionResponse[];

  /**  */
  sessionDate?: SessionDate;

  /**  */
  sessionLocation?: string;

  /**  */
  comment?: string;
}

export interface SetLink {
  /**  */
  offerLink: string;

  /**  */
  agencyId: string;

  /**  */
  qualificationLabel: string;

  /**  */
  qualificationId: string;

  /**  */
  companyId: string;

  /**  */
  companySiret: string;

  /**  */
  companyName: string;
}

export interface SetLinksParamsDto {
  /**  */
  links: SetLink[];
}

export interface GetPreselectionsDateFilters {
  /**  */
  startDate: SessionDate;

  /**  */
  endDate?: SessionDate;

  /**  */
  filterStrategy: EnumGetPreselectionsDateFiltersFilterStrategy;
}

export interface GetPreselectionsFilters {
  /**  */
  limit: number;

  /**  */
  offset: number;

  /**  */
  candidateEmail: string;

  /**  */
  callingPeriod: GetPreselectionsDateFilters;
}

export interface OSMPreselectionsDtosPreselectionsSearchMultiCriteriaResponsesDto {
  /**  */
  questionId?: string;

  /**  */
  value?: number;
}

export interface OSMCommonModelDateDto {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  day?: number;
}

export interface OSMPreselectionsDtosPreselectionsSearchMultiCriteriaDto {
  /**  */
  id?: string;

  /**  */
  agencyId?: string;

  /**  */
  qualificationId?: string;

  /**  */
  originId?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  zipCode?: string;

  /**  */
  city?: string;

  /**  */
  responses?: OSMPreselectionsDtosPreselectionsSearchMultiCriteriaResponsesDto[];

  /**  */
  retainedPreselection?: number;

  /**  */
  sessionDate?: OSMCommonModelDateDto;

  /**  */
  sessionLocation?: string;

  /**  */
  comment?: string;

  /**  */
  companyId?: string;
}

export interface GetPreselectionsResponse {
  /**  */
  preselections: OSMPreselectionsDtosPreselectionsSearchMultiCriteriaDto[];
}

export enum RightsProfile {
  'SUPERADMIN' = 'SUPERADMIN',
  'ADMIN_CGC' = 'ADMIN_CGC',
  'EDITOR_CGC' = 'EDITOR_CGC',
  'ADMIN_HOME_EDITION' = 'ADMIN_HOME_EDITION',
  'EDITOR_HOME_EDITION' = 'EDITOR_HOME_EDITION',
}

export enum UnitaryRight {
  'ADD_ADMIN' = 'ADD_ADMIN',
  'ADD_ADMIN_CGC' = 'ADD_ADMIN_CGC',
  'EDIT_CGC' = 'EDIT_CGC',
  'EDIT_QUALIFICATIONS' = 'EDIT_QUALIFICATIONS',
  'ADD_ADMIN_HOME_EDITION' = 'ADD_ADMIN_HOME_EDITION',
  'EDIT_HOME_EDITION' = 'EDIT_HOME_EDITION',
}

export enum Profile {
  'SUPERADMIN' = 'SUPERADMIN',
  'ADMIN_CGC' = 'ADMIN_CGC',
  'EDITOR_CGC' = 'EDITOR_CGC',
  'ADMIN_HOME_EDITION' = 'ADMIN_HOME_EDITION',
  'EDITOR_HOME_EDITION' = 'EDITOR_HOME_EDITION',
}

export enum HomePageFormat {
  'BIG_IMAGE' = 'BIG_IMAGE',
  'SMALL_IMAGE' = 'SMALL_IMAGE',
  'NO_IMAGE' = 'NO_IMAGE',
}

export enum RandstadAppEnum {
  'EDP' = 'EDP',
  'FLASH' = 'FLASH',
  'RECRUTLIVE' = 'RECRUTLIVE',
  'PUZZLE' = 'PUZZLE',
  'SATISFACTION' = 'SATISFACTION',
  'TDP' = 'TDP',
  'PIGE' = 'PIGE',
  'SCOPE' = 'SCOPE',
  'IT' = 'IT',
  'BDP' = 'BDP',
  'CUSTOM_RANDY' = 'CUSTOM_RANDY',
}

export enum OfferStatus {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
}
export enum EnumGetPreselectionsDateFiltersFilterStrategy {
  'ISBETWEEN' = 'ISBETWEEN',
  'CALLINGDATEAFTERSTARTDATE' = 'CALLINGDATEAFTERSTARTDATE',
}
