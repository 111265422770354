import React, { useState } from 'react';

import { Button, ParametersPopup } from '@randstad-lean-mobile-factory/react-components-core';
import { Gear } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { AppIcon } from 'src/Assets';

import styles from './AdminParametersPopOverMenu.module.scss';
import { Props } from './AdminParametersPopOverMenu.types';

const AdminParametersPopOverMenu = (props: Props) => {
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  return (
    <ParametersPopup
      trigger={
        <div className={styles.container}>
          <Button.Tertiary
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Gear title="gear" />
          </Button.Tertiary>
        </div>
      }
      position="top left"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
      children={
        <Button.Tertiary
          className={styles.button}
          onClick={() => {
            history.push('/');
          }}
        >
          <AppIcon viewBox="0 0 16 16" className={styles.appIcon} />
          revenir au portail mobilité
        </Button.Tertiary>
      }
      {...props}
    />
  );
};
export default AdminParametersPopOverMenu;
