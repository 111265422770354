import React from 'react';

import { Button, FetchButton, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import { BDPDeleteWarning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';

import { useDeleteAppDetails } from 'src/Hooks/HomeEdition/useDeleteAppDetails';
import { toFetchStatus } from 'src/Services/Async';
import { DeleteAppDetailsState } from 'src/Services/Routing';

import styles from './DeleteAppDetailsModal.module.scss';

const DeleteAppDetailsModal = () => {
  const history = useHistory();
  const location = useLocation<DeleteAppDetailsState>();
  const modalParameters = location.state.modalParameters;
  const deleteAppDetails = useDeleteAppDetails();

  const onDelete = () => {
    modalParameters &&
      modalParameters?.app &&
      deleteAppDetails.mutate({
        app: modalParameters.app,
      });
  };
  const onClose = () => {
    history.goBack();
  };

  return (
    <Modal
      onClose={onClose}
      title="Attention"
      open
      icon={<BDPDeleteWarning />}
      footerActionsRight={[
        <Button.Secondary onClick={onClose} text="annuler" />,
        <FetchButton
          className={styles.deleteButton}
          title="supprimer"
          onClick={onDelete}
          fetchStatus={toFetchStatus(deleteAppDetails)}
          onSuccess={onClose}
        />,
      ]}
    >
      <div>
        Vous vous apprêtez à supprimer définitivement les détails de cette application.
        Confirmez-vous ce choix ?
      </div>
    </Modal>
  );
};

export default DeleteAppDetailsModal;
