import { UseMutationOptions, useMutation } from 'react-query';

import { CalendarService } from 'src/Services/API';

export const useRemoveAgencyCalendar = (
  config?: UseMutationOptions<void, Error, { agencyId: string; calendarObjectId: string }, unknown>
) => {
  return useMutation(async params => {
    return await CalendarService.calendarControllerRemoveCalendar(params);
  }, config);
};
