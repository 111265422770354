import React, { useState } from 'react';

import { Button, FetchButton, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import { ListIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';

import { useAddAppDetails } from 'src/Hooks/HomeEdition/useAddAppDetails';
import { useUpdateAppDetails } from 'src/Hooks/HomeEdition/useUpdateAppDetails';
import { RandstadAppEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { EditAppDetailsState } from 'src/Services/Routing';
import { appsConstants } from 'src/Utils/AppsDetailsValues/AppsDetailsValues';

import { EditionAppDetailsMode } from './EditAppDetailsModal.types';
import EditAppDetailsModalContent from './EditAppDetailsModalContent/EditAppDetailsModalContent.component';

const modalTitle = {
  [EditionAppDetailsMode.CREATE]: 'ajouter une application',
  [EditionAppDetailsMode.MODIFY]: "modifier l'application'",
};

const hook = {
  [EditionAppDetailsMode.CREATE]: useAddAppDetails,
  [EditionAppDetailsMode.MODIFY]: useUpdateAppDetails,
};

const EditAppDetailsModal = () => {
  const history = useHistory();
  const location = useLocation<EditAppDetailsState>();
  const modalParameters = location.state.modalParameters;
  const editionAppDetailsMode =
    modalParameters?.editionAppDetailsMode ?? EditionAppDetailsMode.CREATE;
  const appDetail = modalParameters?.appDetail;

  const [app, setApp] = useState<RandstadAppEnum | undefined>(appDetail?.app ?? undefined);
  const [description, setDescription] = useState<string>(appDetail?.description ?? '');
  const [isHighlighted, setIsHighlighted] = useState<boolean>(appDetail?.isHighlighted ?? false);
  const editAppDetails = hook[editionAppDetailsMode]();
  const onClose = () => {
    history.goBack();
  };

  return (
    <Modal
      open
      icon={<ListIcon />}
      title={modalTitle[editionAppDetailsMode]}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setApp(appDetail?.app ?? undefined);
            setDescription(appDetail?.description ?? '');
            setIsHighlighted(appDetail?.isHighlighted ?? false);
          }}
        >
          réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={
            !app ||
            description.trim().length === 0 ||
            (description === appDetail?.description && isHighlighted === appDetail?.isHighlighted)
          }
          onClick={() =>
            app &&
            editAppDetails.mutate({
              appDetails: [
                {
                  ...appDetail,
                  app,
                  description,
                  isHighlighted,
                },
              ],
            })
          }
          fetchStatus={toFetchStatus(editAppDetails)}
          onSuccess={onClose}
        />,
      ]}
      onClose={onClose}
    >
      <EditAppDetailsModalContent
        editionAppDetailsMode={editionAppDetailsMode}
        app={app}
        category={app && appsConstants[app] ? appsConstants[app].category : 'non renseignée'}
        description={description}
        isHighlighted={isHighlighted}
        setApp={setApp}
        setDescription={setDescription}
        setIsHighlighted={setIsHighlighted}
      />
    </Modal>
  );
};

export default EditAppDetailsModal;
